export const TEST = 'TEST';

export enum WidthType {
  SM = 'sm',
  XL = 'xl',
  MD = 'md',
  LG = 'lg',
  XS = 'xs'
}

export enum ReportType {
	ACTIVE_ASSET = 'active-asset',
	ASSET_EXPIRATION = 'asset-expiration',
	ASSET_EARLIEST_EXPIRATION = 'asset-earliest-expiration',
	CAST_EXPIRATION = 'cast-expiration',
	NEXT_HOLDING_FEE_DUE = 'next-holding-fee-due',
	USE_RIGHTS_EXPIRING = 'use-rights-expiring',
	GUARANTEE_EXPIRING = 'guarantee-expiring',
	ACTIVE_ELEMENTS = 'active-elements',
	ELEMENT_EXPIRATION = 'element-expiration'
}

export enum IMAGE_ORIENTATION {
	NORMAL = 1,
	MIRROR = 2,
	NORMAL_180 = 3,
	MIRROR_180 = 4,
	ANTI_CLOCKWISE_MIRROR_90 = 5,
	ANTI_CLOCKWISE_90 = 6,
	CLOCKWISE_MIRROR_90 = 7,
	CLOCKWISE_90 = 8,
}

export enum COMPANY_MAIN_TYPE {
	AD_AGENCY = 'AD AGENCY',
	ADVERTISER = 'ADVERTISER',
	AGENT_REPRESENTATIVE = 'AGENT/REPRESENTATIVE',
	CASTING_COMPANY = 'CASTING COMPANY',
	DIVISION = 'DIVISION',
	HOSPITAL = 'HOSPITAL',
	LOAN_OUT_CORPORATION = 'LOAN OUT CORPORATION',
	LOCATION = 'LOCATION',
	PRODUCTION_COMPANY = 'PRODUCTION COMPANY',
	VENDOR = 'VENDOR',
}

export enum PERSON_TYPE {
	AGENT = 'AGENT',
	CREW = 'CREW',
	STAFF = 'STAFF',
	TALENT = 'TALENT',
	VENDOR = 'VENDOR'
}

export enum ElementType {
	Art = 'ART',
	Audio = 'AUDIO',
	BrandedItems = 'BRANDED ITEMS',
	Company = 'COMPANY',
	Image = 'IMAGE',
	Landmarks = 'LANDMARKS',
	Music = 'MUSIC',
	Trademarks = 'TRADEMARKS',
	Vehicle = 'VEHICLE',
	Video = 'VIDEO',
	Misc = 'MISC/OTHERS',
	Props = 'PROPS/OTHERS',
	Fonts = 'FONTS',
	Books = 'BOOKS',
	Quotes = 'QUOTES'
}

export enum COMPANY_INFO_TAB {
	AD_ID = 'AD_ID',
	ADDRESS = 'Address',
	BUSINESS_TAX_INFO = 'Business_tax_info',
	COMPANY_HIERARCHY = 'Company_hierarchy',
	COMPANY_PROFILE = 'Company_profile',
	DOCUMENTS = 'Document',
	PAYROLL_VENDOR = 'Payroll_vendor',
	PRODUCT = 'Product',
	RELATIONSHIPS = 'Relationships',
	SOCIAL_MEDIA = 'Social_media',
	STAFF = 'Staff',
	TRANSFER_OF_RIGHTS = 'Transfer_of_rights',
	MEMBERS='Members',
	SIGNATORY='SIGNATORY',
	ADVERTISER='ADVERTISER',
	MINOR_PERMIT='MINOR_PERMIT',
	INSURANCE = 'INSURANCE',
	NOTES = 'NOTES',
	BRANDS_PRODUCTS = 'Brands_products',
	BANK = 'Banks'
}

export enum COMPANY_FORM {
	ADDRESS = 'Address',
	BUSINESS_TAX_INFO = 'Business Tax Info',
	PRODUCT = 'Product',
	SOCIAL_MEDIA = 'Social Media',
	SIGNATORY='Signatory',
}

export enum TRAVEL {
	INTERNATIONAL_DRIVERS_LISCENSE = 'Int. drivers liscense',
	TSA = 'TSA',
	PASSPORT = 'Passport',
	AIRLINE = 'Airline',
	HOTEL = 'Hotel',
	RENTAL_CAR_SERVICES = 'Rental car services'
}

export enum PEOPLE_INFO_TAB {
	SOCIAL_MEDIA = 'Social meadia',
	CONTACT = 'Contact',
  ADDRESS = 'Address',
	AGENT_INFO = 'Agent Info',
  LOAN_OUT_CORPORATION_INFO = 'Loan Out Corporation Info',
  EMERGENCY_CONTACT = 'Emergency Contact',
  SPECIFICATIONS = 'Specifications',
  TRAVEL_INFO = 'Travel Info',
  INDIVIDUAL_ID_WITH_PAYROLL_VENDOR = 'ID w/Payroll Vendor',
  BANK_INFO = 'Bank Info',
  W4_INDIVIDUAL_WITHHOLDING = 'W4 Individual Withholding',
  W9_CORP_BUS_WITHHOLDING = 'W-9 Corp/Bus Withholding',
  W8BEN = 'W-8BEN',
  LOGS = 'Logs',
  PHOTOS = 'Photos',
  BANKING_TAXES='Banking/Taxes'
}

export enum ELEMENT_INFO_TAB {
	ELEMENT_PROFILE = 'Element_Profile',
	LICENSE_DETAILS = 'License_Details',
	MUSIC = 'Music',
	TALENT = 'Talent',
	RIGHTS_ACQUIRED = 'Rights_Acquired',
	RIGHTS_USED = 'Rights_Used',
}

export enum ADDRESS_TYPE {
	PERMANENT = 'Permanent',
	TEMPORARY = 'Temporary',
}

export enum OPERATORS {
	LESS_THAN = 'lt',
	GREATER_THAN = 'gt',
	LESS_THAN_OR_EQUALS = 'lte',
	GREATER_THAN_OR_EQUALS = 'gte',
	EQUAL = 'eq',
	NOT_EQUAL = 'nq',
	CONTAINS = 'cn',
	NOT_CONTAINS = 'nc',
	STARTS_WITH = 'sw',
	ENDS_WITH = 'ew',
	IN = 'in',
	NOT_IN = 'ni',
}

export enum TIME_UNIT {
	Seconds = 's',
	Minutes = 'm'
}

export enum UNION_TYPE {
	SAG_AFTRA = 'SAG AFTRA',
	AFM = 'AFM',
	ACTRA = 'ACTRA',
	AFTRA = 'AFTRA',
	SAG = 'SAG',
	UDA = 'UDA',
	PRINT = 'PRINT',
}

export enum SESSION_MEDIA_TYPE {
	CORPORATE_EDUCATIONAL_BROADCAST = 'CORPORATE/EDUCATIONAL/BROADCAST',
	DIGITAL = 'DIGITAL',
	MUSIC = 'MUSIC',
	PRINT = 'PRINT',
	RADIO = 'RADIO',
	TV = 'TV',
	// Non- union types
	MULTI = 'MULTI',
	TV_COMMERCIAL = 'TV COMMERCIAL',
	RADIO_COMMERCIAL = 'RADIO COMMERCIAL',
	INDUSTRIAL_NON_BROADCAST = 'INDUSTRIAL/NON-BROADCAST',
	INFOMERCIAL = 'INFOMERCIAL',
	BEHIND_THE_SCENE_BTS= 'BEHIND THE SCENE (BTS)',
	FILM = 'FILM',
	GAMES = 'GAMES',
	HISTORICAL= 'HISTORICAL',
	LIVE_EVENT= 'LIVE EVENT',
	MUSIC_VIDEO= 'MUSIC VIDEO',
	PROMO ='PROMO',
	PUBLIC_RELATIONS_EVENT='PUBLIC RELATIONS EVENT',
	RADIO_PROGRAM='RADIO PROGRAM',
	SPONSORSHIP='SPONSORSHIP',
	TRAILER ='TRAILER',
	TV_PROGRAM='TV PROGRAM',
}

export enum SESSION_COMMON_FIELD {
	PAYROLL_COMPANY = 'Payroll Company',
	INVOICE = 'Invoice #',
	INVOICE_DATE = 'Invoice date',
	NOTES = 'Notes',
}

export enum SESSION_ADDITIONAL_COLUMN {
	NETWORK_USES = 'Network Uses',
	ENTRY = 'Entry #',
	AIR_DATE = 'Air Date',
	NETWORK = 'Network',
	PROGRAM = 'Program',
	EDIT = 'Edit',
	ADDITIONAL_UNITS = 'Additional Units',
	UNIT_WEIGHT = 'Unit weight',
	UPGRADE_X_TO_X = 'Upgrade x to X',
}

export enum CAST_LIST_TAB {
	ALL = 'all',
	FINAL = 'final'
}

export enum RIGHTS_TRACKER_TAB {
	LIST = 'list',
	CALENDAR = 'calendar'
}

export enum EditVersionType {
	Edit = 0,
	Version = 1,
}

export enum LocationType {
	Filming = 0,
	Recording = 1,
	MusicStudio = 2,
	Other = 3
}

export enum AppError {
	Unknown = 0,
	DuplicateName = 1,
	NotFound = 2,
	InvalidData = 3,
}

export enum SessionCycleLength {
	DAYS_30 = 30,
	WEEKS_1 = 7,
	WEEKS_4 = 28,
	WEEKS_8 = 56,
	WEEKS_13 = 91,
	WEEKS_26 = 182,
	WEEKS_52 = 364,
	MONTHS_12 = 365,
	MONTHS_18 = 547,
	MONTHS_21 = 638,
	MONTHS_24 = 730,
	YEAR_1 = 365,
	YEARS_2 = 730,
	YEARS_3 = 1095,
}

export enum ImportStatus
{
    Completed = 'Completed',
    Queued = 'Queued',
    InProgress = 'In progress',
    Failed = 'Failed'
}

export enum RightsCoverageTypes
{
	BROADCAST = 'BROADCAST',
	DIGITAL = 'DIGITAL',
	OUTOFHOME = 'OUT OF HOME',
	PRINT = 'PRINT',
}

export enum RightsCoverageMainTypes
{
	BROADCAST_LINEAR = 'BROADCAST/LINEAR',
	AUDIO_RADIO = 'AUDIO/RADIO',
	SOCIALMEDIA = 'SOCIAL MEDIA',
	RETAIL = 'RETAIL',
	PUBLICATIONS = 'PUBLICATIONS'  
}

export enum SocialMediaTypeEnums {
	FACEBOOK = 'FACEBOOK',
	INSTAGRAM = 'INSTAGRAM',
	LINKEDIN = 'LINKEDIN',
	SKYPE = 'SKYPE',
	SNAPCHAT = 'SNAPCHAT',
	TUMBLR = 'TUMBLR',
	TWITTER = 'TWITTER',
	YOUTUBE = 'YOUTUBE',
	TIKTOK = 'TIKTOK',
	PINTEREST = 'PINTEREST'
}

export enum MISCELLANEOUS_TYPE {
	CONTRACTS = 'CONTRACTS',
	MATERIALS = 'MATERIALS',
	REQUESTS = 'REQUESTS',
	OTHER = 'OTHER'
}

export enum ASSET_FILE_TYPE {
	AUDIO ='AUDIO',
	IMAGE ='IMAGE',
	INTERACTIVE ='INTERACTIVE',
	PRINT ='PRINT',
	VIDEO ='VIDEO',
	OTHER ='OTHER',
}

export const enum CAST_ABBREVIATION_CONTROL {
	ON_CAMERA = 'ON_CAMERA',
	OFF_CAMERA = 'OFF_CAMERA',
	BOTH = 'BOTH',
} 

export const enum ENTITY_TYPE {
	ASSET = 'ASSET',
	ELEMENT = 'ELEMENT',
}

export const enum  ASSET_STATUS{
	INCOMPLETE = 'INCOMPLETE',
	ACTIVE = 'ACTIVE',
	EXPIRED = 'EXPIRED',
	RELEASED = 'RELEASED',
	RELEASED_USE_INACTIVE = 'RELEASED (USE INACTIVE)',
	RELEASED_USE_ACTIVE = 'RELEASED (USE ACTIVE)',
	NA = 'NA'
}

export const enum USE_RIGHT_TYPE {
	// NOTE: Include the values as needed
	NETWORK = 'NETWORK',
	HOLDING_FEES = 'HOLDING FEES',
	DIGINET = 'DIGINET',
	SESSION_FEE = 'SESSION FEE',
	ION_DIGINET = 'ION/DIGINET'
}

export const enum MEDIAINFOUNIONTYPES {
	UNION = 'UNION',
	NON_UNION = 'NON UNION'
}

export const enum MEDIAINFOMEDIATYPES {
	TV_COMMERCIAL = 'TV COMMERCIAL',
	AUDIO_RADIO_COMMERCIAL = 'AUDIO/RADIO COMMERCIAL',
	AUDIO_FLEX = 'AUDIO FLEX',
	CORPORATE_EDUCATIONAL = 'CORPORATE EDUCATIONAL',
	ALL_MEDIA = 'ALL MEDIA',
	BROADCAST_LINEAR = 'BROADCAST/LINEAR',
	DIGITAL = 'DIGITAL',
	SOCIAL_MEDIA = 'SOCIAL MEDIA',
	STREAMING_OVER_THE_TOP_OTT = 'STREAMING/OVER THE TOP (OTT)',
	AUDIO_RADIO = 'AUDIO/RADIO',
	INDUSTRIAL_OUT_OF_HOME_OOH = 'INDUSTRIAL/OUT OF HOME(OOH)',
	PRINT = 'PRINT'
}

export const enum MEDIAINFOMEDIASUBTYPES {
	CABLE = 'CABLE',
	WILDSPOT = 'WILDSPOT',
	STREAMING_PLATFORMS = 'STREAMING PLATFORMS',
	SOCIAL_MEDIA = 'SOCIAL MEDIA',
	FOREIGN = 'FOREIGN',
	WILDSPOT_ARC = 'WILDSPOT ARC',
	NATIONAL_USE = 'NATIONAL USE',
	REGIONAL_USE = 'REGIONAL USE',
	CATEGORY_I = 'CATEGORY I',
	CATEGORY_II = 'CATEGORY II',
}